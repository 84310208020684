<template>
    <div class="right-box" >
        <div class="title" v-if="rightdata.title">{{rightdata.title}}</div>
       <div class="content" v-for="(item,index) in getlist" :key="index" v-if="index==active">
        <single-line-text :show="rightdata.name" @diyclcik="diyclcik"></single-line-text>
        <diyMultilinetext :show="rightdata.name" @diyclcik="diyclcik"></diyMultilinetext>
        <diydropdownmenu :show="rightdata.name" @diyclcik="diyclcik"></diydropdownmenu>
        <diyUploadpictures :show="rightdata.name" @diyclcik="diyclcik"></diyUploadpictures>
        <diyRadio :show="rightdata.name"  @diyclcik="diyclcik"></diyRadio>
        <diycheckbox :show="rightdata.name" @diyclcik="diyclcik"></diycheckbox>
        <diyMobilephoneverification :show="rightdata.name" @diyclcik="diyclcik"></diyMobilephoneverification>
        <diylocation :show="rightdata.name" @diyclcik="diyclcik"></diylocation>
        <diytitle :show="rightdata.name" @diyclcik="diyclcik"></diytitle>
        <diyButton :show="rightdata.name" @diyclcik="diyclcik"></diyButton>
       </div>
    </div>
</template>
<script>
import {mapState,mapMutations,mapGetters,mapActions} from 'vuex'
import diySinglelinetext from './diy/Singlelinetext.vue'
import diyMultilinetext from './diy/Multilinetext.vue'
import diydropdownmenu from './diy/dropdownmenu.vue'
import diyUploadpictures from './diy/Uploadpictures.vue'
import diyRadio from './diy/Radio.vue'
import diycheckbox from './diy/checkbox.vue'
import diyMobilephoneverification from './diy/Mobilephoneverification.vue'
import diylocation from './diy/location.vue'
import diytitle from './diy/title.vue'
import diyButton from './diy/Button.vue'

export default {
    data() {
        return {

        }
    },
    components:{
        'single-line-text':diySinglelinetext,
        diyMultilinetext,
        diydropdownmenu,
        diyUploadpictures,
        diyRadio,
        diycheckbox,
        diyMobilephoneverification,
        diylocation,
        diytitle,
        diyButton
    },
  
    props:{
        rightdata:{
            default(){
                return {
                    title:'',
                    name:''
                }
            }
        }
    },
watch:{
    rightdata:{
        handler(val){
            console.log(val)
        }
    }
},
 computed: {
    ...mapState({
      getlist: (state) => {
        return state.formdiy.getlist;
      },
       active: (state) => {
        return state.formdiy.active;
      }
    }),
  },
    methods:{
        ...mapMutations(['addlistStore','setlistStore']),
        diyclcik(val){
            console.log(val)
          this.setlistStore(val)
        }
    }
}
</script>
<style lang="scss" scoped>
.right-box{
    width: 100%;
     .title{
        height:46px ;
        line-height: 46px;
        width: 100%;
        padding-left:24px ;
        background: #EDEFFF;
    }
    .content{
        padding:24px ;
    }
}
</style>