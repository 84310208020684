<template>

  <div class="center-content">
    <div style="display:flex;justify-content: space-between;align-items: center;">
      <!-- <div>9:41</div> -->
      <div>
        <!-- <img src="@/assets/images/formimg/Cellular.png" alt="" style="width:21px;height:11px"> -->
   <img src="@/assets/images/formimg/head-diy.png" alt="" >
      </div>
    </div>
    <div
      v-for="(item, index) in getlist"
      :key="index"
     
    >
    <div v-if="index == activeIndex" class="del-box-left">
     <div class="icon-left" @click="leftclcik(item,index,'del')"> <i class="el-icon-delete-solid" ></i></div>
      <div class="icon-left" @click="leftclcik(item,index,'add')"><i class="el-icon-plus" ></i></div>

    </div>
     <div v-if="index == activeIndex" class="up-box-right">
     <div class="icon-left" @click="rightclcik(item,index,'up')"> <i class="el-icon-arrow-up"></i></div>
      <div class="icon-left" @click="rightclcik(item,index,'down')"><i class="el-icon-arrow-down"></i></div>

    </div>
      <!-- {{ item }} -->
      <div  :class="[index == activeIndex ? 'active' : '']"  @click="centerclcik(item,index)">
      <div v-if="item.name == 'Singlelinetext'" class="item-list">
        <div style="height: 30px">
          {{ item.data.title ? item.data.title : "名称" }}
          <span v-if="item.data.is_required == 1" class="red">*</span>
        </div>
        <el-input
          v-model="item.data.placeholder"
          placeholder="请输入名称"
        ></el-input>
      </div>
      <div v-if="item.name == 'Multilinetext'" class="item-list">
        <el-input
          type="textarea"
          v-model="item.data.placeholder"
          placeholder="请填写详细地址"
          resize="none"
        ></el-input>
      </div>
      <div v-if="item.name == 'dropdownmenu'" class="item-list">
        <div style="height: 30px">
          {{ item.data.title ? item.data.title : "内容标题" }}
          <span v-if="item.data.is_required == 1" class="red">*</span>
        </div>
        <div
          style="
            border: 1px solid #dcdfe6;
            color: #606266;
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 38px;
            padding: 0 12px;
            font-size:14px;
            border-radius: 4px;
          "
        >
          {{ item.data.placeholder ? item.data.placeholder : "请填写省市区"
          }}<i class="el-icon-arrow-down" style="color:#8B8B8B"></i>
        </div>
        <!-- <el-input
          suffix-icon="el-icon-arrow-down"
          placeholder="请填写省市区"
        ></el-input> -->
      </div>
      <div v-if="item.name == 'Uploadpictures'" class="item-list">
        <div style="height: 30px">
          {{ item.data.title ? item.data.title : "内容标题" }}
          <span v-if="item.data.is_required == 1" class="red">*</span>
        </div>
        <div style="color:#999;font-size:14px;height: 30px"> {{ item.data.placeholder ? item.data.placeholder : "请上传" }}</div>
        <el-upload
          action="https://jsonplaceholder.typicode.com/posts/"
          list-type="picture-card"
        >
          <i class="el-icon-plus"></i>
        </el-upload>
      </div>
      <div v-if="item.name == 'Radio'" class="item-list">
        <div style="height: 30px">
          {{ item.data.title ? item.data.title : "内容标题" }}
          <span v-if="item.data.is_required == 1" class="red">*</span>
        </div>
        <div v-for="(item2,index2) in item.data.list" :key="index2">
          <el-radio
            v-model="item2.active"
            label="1"
            border
            style="width: 100%; margin: 0 0 10px"
            >{{item2.label}}</el-radio
          >
        </div>
      </div>
      <div v-if="item.name == 'checkbox'" class="item-list">
        <div style="height: 30px">
          {{ item.data.title ? item.data.title : "内容标题" }}
          <span v-if="item.data.is_required == 1" class="red">*</span>
        </div>
         <div v-for="(item2,index2) in item.data.list" :key="index2">
             <el-checkbox
            v-model="item2.active"
            :label="item2.label"
            border
            style="width: 100%; margin: 0 0 10px"
          ></el-checkbox>
        </div>
        
      </div>
      <div v-if="item.name == 'Mobilephoneverification'" class="item-list">
        <div style="height: 30px">
          {{ item.data.title ? item.data.title : "手机授权" }}
          <span v-if="item.data.is_required == 1" class="red">*</span>
        </div>
        <div
          style="
            height: 48px;
            background: #f6fafd;
            color: #2d83ff;
            line-height: 48px;
            padding-left: 15px;
          "
        >
        <i class="el-icon-mobile"></i>
          {{ item.data.placeholder ? item.data.placeholder : "点击授权手机号" }}
        </div>
      </div>
      <div v-if="item.name == 'location'" class="item-list">
        <div style="height: 30px">
          {{ item.data.title ? item.data.title : "定位" }}
          <span v-if="item.data.is_required == 1" class="red">*</span>
        </div>
        <div
          style="
            height: 48px;
            background: #f6fafd;
            color: #2d83ff;
            line-height: 48px;
            padding-left: 15px;
          "
        >
          <i class="el-icon-location-outline"></i>
          {{ item.data.placeholder ? item.data.placeholder : "点击获取位置信息" }}
        </div>
      </div>
      <div v-if="item.name == 'title'" class="item-list">
        <div
          style="
            height: 30px;
            color: #222227;
            font-weight: 600;
            font-size: 18px;
          "
        >
          {{ item.data.title ? item.data.title : "这里是标题事例" }}
          <span v-if="item.data.is_required == 1" class="red">*</span>
        </div>
        <div style="color:#999">{{ item.data.placeholder ? item.data.placeholder : "" }}</div>
      </div>
      <div v-if="item.name == 'Button'" class="item-list">
        <el-button type="primary" style="background: #2d83ff; width: 100%" @click="twobtn(item.data)">
          {{ item.data.title ? item.data.title : "提交" }}
        </el-button>
      </div>
      </div>
    </div>
    <!-- {{ this.$store.state.formdiy }}787 -->
  </div>
</template>
<script>
import { mapState, mapMutations, mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {
      // activeIndex:0
    };
  },
  watch: {
    centerdata: {
      handler(val) {
        return val;
      },
      deep: true,
      immediate: true,
    },
    activeIndex:{
       handler(val) {
         this.setactiveStore(val)
      },
      deep: true
    }
  },
  computed: {
    ...mapState({
      getlist: (state) => {
        return state.formdiy.getlist;
      },
    }),
  },
  props: {
    centerdata: {
      type: [Array, Object],
    },
    showtitle: {
      type: [Array, Object, String],
    },
    activeIndex:{
       type: [Number, Object, String],
    }
  },
  methods: {
       ...mapMutations(['addlistStore','putlistStore','removelistStore','setactiveStore']),
    centerclcik(e,index) {
      this.$emit('select',e,index)
    },
    leftclcik(item,i,val){
      if(val=='del'){
        this.removelistStore(i)
        if(i== this.getlist.length){
           this.$emit('left',item,i,val)
        }
      }else{
        this.putlistStore(item)
      }
      
       this.$emit('right',item,i,val)
    },
    rightclcik(item,i,val){
  
      console.log(item,'------------------------')
      this.$emit('right',item,i,val)
    },
    twobtn(e){
      if(e.is_required){
        this.$confirm(e.placeholder,  {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
        }).then(() => {
       
        }).catch(() => {
               
        });
      }
      console.log(e)
       
    }
  },
};
</script>
<style lang="scss" scoped>
.del-box-left{
  position: absolute;
    // left: 560px;
    left: -26px;
    display: flex;
    z-index: 1;
    flex-direction: column;
    .icon-left{
      width: 26px;
      height: 26px;
      background: #4458fe;
      color: #fff;
      text-align: center;
      line-height: 26px;
      // padding: 5px;
      font-size: 18px;
      margin-bottom: 10px;
    }
}
.up-box-right{
   position: absolute;
    // left: 560px;
    right: -26px;
    display: flex;
    z-index: 1;
    flex-direction: column;
    .icon-left{
      width: 26px;
      height: 26px;
      background: #4458fe;
      color: #fff;
      text-align: center;
      line-height: 26px;
      // padding: 5px;
      font-size: 18px;
      margin-bottom: 10px;
    }
}
.red {
  color: red;
}
.item-list {
  margin: 15px;
}
.active {
  border: 1px #4458fe dashed;
 
}
.center-content {
   position: relative;
  width: 375px;
  min-width: 375px;
  max-width: 375px;
  // max-height: 600px;
  // overflow: hidden;
  // overflow-y: scroll;
  // overflow-x: visible;
  margin: 0 80px;
  border: 1px #f5f6fa solid;
  box-shadow: 0px 0px 9px 1px rgba(153, 153, 153, 0.1);
  //
 
}
</style>