<template>
  <div class="left-box">
    <div v-for="item in leftdata" :key="item.name">
      <div class="title">{{ item.name }}</div>
      <div class="item-box">
        <!-- {{item2.img}} -->
        <div
          v-for="(item2, index2) in item.data"
          :key="index2"
          class="item"
          @click="leftclick(item2)"
        >
          <!-- {{item2.img}} -->
          <img
            src="@/assets/images/formimg/text.png"
            alt=""
            class="img"
            v-if="item2.name == 'Singlelinetext'"
          />
          <img
            src="@/assets/images/formimg/Multilinetext.png"
            alt=""
            class="img"
            v-if="item2.name == 'Multilinetext'"
          />
          <img
            src="@/assets/images/formimg/dropdownmenu.png"
            alt=""
            class="img"
            v-if="item2.name == 'dropdownmenu'"
          />
          <img
            src="@/assets/images/formimg/Uploadpictures.png"
            alt=""
            class="img"
            v-if="item2.name == 'Uploadpictures'"
          />
          <img
            src="@/assets/images/formimg/Radio.png"
            alt=""
            class="img"
            v-if="item2.name == 'Radio'"
          />
          <img
            src="@/assets/images/formimg/checkbox.png"
            alt=""
            class="img"
            v-if="item2.name == 'checkbox'"
          />
          <img
            src="@/assets/images/formimg/Mobilephoneverification.png"
            alt=""
            class="img"
            v-if="item2.name == 'Mobilephoneverification'"
          />
          <img
            src="@/assets/images/formimg/location.png"
            alt=""
            class="img"
            v-if="item2.name == 'location'"
          />
          <img
            src="@/assets/images/formimg/title.png"
            alt=""
            class="img"
            v-if="item2.name == 'title'"
          />
          <img
            src="@/assets/images/formimg/Button.png"
            alt=""
            class="img"
            v-if="item2.name == 'Button'"
          />
          <span style="margin-top: 5px"> {{ item2.title }}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapMutations, mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {};
  },
  computed: {
    ...mapState({
      getlist: (state) => {
        return state.formdiy.getlist;
      },
    }),
  },
  props: {
    leftdata: {
      default() {
        return [
          {
            name: "基础组件",
            data: [
              {
                name: "Singlelinetext",
                title: "单行文本",
                img: "",
              },
              {
                name: "Multilinetext",
                title: "多行文本",
                img: "",
              },
              {
                name: "dropdownmenu",
                title: "下拉菜单",
                img: "",
              },
              {
                name: "Uploadpictures",
                title: "上传图片",
                img: "",
              },
              {
                name: "Radio",
                title: "单选框",
                img: "",
              },
              {
                name: "checkbox",
                title: "复选框",
                img: "",
              },
              {
                name: "Mobilephoneverification",
                title: "手机验证",
                img: "",
              },
              {
                name: "location",
                title: "定位",
                img: "",
              },
              {
                name: "title",
                title: "标题",
                img: "",
              },
              {
                name: "Button",
                title: "按钮",
                img: "",
              },
            ],
          },
        ];
      },
    },
  },
  methods: {
    ...mapMutations(["addlistStore"]),
    leftclick(e) {
      console.log(e, "left");
      this.addlistStore(e);
      this.$emit("leftClick", e, this.getlist);
    },
  },
};
</script>
<style lang="scss" scoped>
.left-box {
  width: 224px;
  margin-top: 20px;
  .title {
    height: 46px;
    line-height: 46px;
    width: 224px;
    padding-left: 24px;
    background: #edefff;
  }
  .item-box {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
  .item {
    width: 50%;
    height: 95px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .img {
      width: 40px;
      height: 40px;
    }
  }
}
</style>