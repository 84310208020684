<template>
<!-- 标题1 -->
    <div v-if="show=='title'">
             <el-form ref="form" :model="form" label-width="80px">
 <el-form-item label="字段属性">
    <el-radio-group v-model="form.data.is_required">
      <el-radio :label="1">必填</el-radio>
      <el-radio :label="0">选填</el-radio>
    </el-radio-group>
  </el-form-item>
  <el-form-item label="标题内容">
    <el-input v-model="form.data.title" placeholder="请输入标题内容"></el-input>
  </el-form-item>
  <el-form-item label="提示文字">
    <el-input v-model="form.data.placeholder" placeholder="请输入提示文字"></el-input>
  </el-form-item>
  <!-- <el-form-item label="字体大小">
   <el-input-number v-model="form.data" @change="handleChange" :min="1" :max="10" label="描述文字"></el-input-number>
  </el-form-item>
    <el-form-item label="字体粗细">
   <el-radio-group v-model="form.data">
      <el-radio label="细"></el-radio>
      <el-radio label="中"></el-radio>
      <el-radio label="粗"></el-radio>
    </el-radio-group>
  </el-form-item> -->
        </el-form>
    </div>
</template>
<script>
import {mapState,mapMutations,mapGetters,mapActions} from 'vuex'
export default {
    data() {
        return {
             form:{
                  id: "input",
       
        name:'title',
        data: {
          is_required: 1,
          placeholder: "",
          title: "",
          type:1
        }
             }
        }
    },
     computed: {
    ...mapState({
      active: (state) => {
        return state.formdiy.active;
      },
    }),
  },
     props:{
        show:{
            type:[String,Number,Boolean,Object]
        }
    },
     watch:{
        active:{
      handler(val){
        if(this.show==this.form.name){
          this.addlistStoredata(this.form)
//  console.log(this.form)
        }
      }
    },
    form:{
      handler(val){
        this.$emit('diyclcik',val)
        console.log('diy---',val)
      },
      // immediate:true,
      deep:true
    }
  },
    methods:{
        ...mapMutations(['addlistStoredata']),
        handleChange(){}
    }
}
</script>
<style lang="scss" scoped>

</style>