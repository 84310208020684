<template>
  <!-- 表单管理 -->

  <div class="formment-box">
    <div class="box">
      <div class="top-nav">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item>表单管理</el-breadcrumb-item>
          <el-breadcrumb-item class="active">编辑</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
     <div style="display:flex">
<left-edit @leftClick="leftClick" :leftdata="leftdata"></left-edit>
<div class="center-box-big">
  <center-edit :centerdata="centerdata" :activeIndex="activeIndex" @left="left" @right="right" @select="select"></center-edit>
</div>
<right-edit :rightdata="rightdata" @rightclcik="rightclcik"></right-edit>
     </div>
      <div class="btn-box">
      <el-button @click="saveclick('保存')">保存</el-button>
      <el-button type="primary" @click="saveclick('另存为')">另存为</el-button>
    </div>
    </div>
  
  </div>
</template>

<script>
import { sessionGetKey } from "@/utils/sessionStorage.js";
import { mapState, mapMutations, mapGetters, mapActions } from "vuex";
import centeredit from './components/editor/centeredit.vue'
import leftedit from './components/editor/leftedit.vue'
import rightedit from './components/editor/rightedit.vue'

export default {
  data() {
    return {
      activeIndex:0,
      leftdata:[{
               name:'基础组件',
               data:[{
                   name:'Singlelinetext',
                   title:'单行文本',
                   img:"@/assets/images/formimg/text.png"
               },{
                    name:'Multilinetext',
                   title:'多行文本',
                   img:'@/assets/images/formimg/Multilinetext.png'
               },{
                     name:'dropdownmenu',
                   title:'下拉菜单',
                   img:'@assets/images/formimg/dropdownmenu.png'
               },{
                    name:'Uploadpictures',
                   title:'上传图片',
                   img:'@/assets/images/formimg/Uploadpictures.png'
               },{
                    name:'Radio',
                   title:'单选框',
                   img:'@/assets/images/formimg/Radio.png'
               },{
                 name:'checkbox',
                   title:'复选框',
                   img:'@/assets/images/formimg/checkbox.png'
               },{
                  name:'Mobilephoneverification',
                   title:'手机验证',
                   img:'@/assets/images/formimg/Mobilephoneverification.png'
               },{
                    name:'location',
                   title:'定位',
                   img:'@/assets/images/formimg/location.png'
               },{
                 name:'title',
                   title:'标题',
                   img:'@/assets/images/formimg/title.png'
               },{
                  name:'Button',
                   title:'按钮',
                   img:'@/assets/images/formimg/Button.png'
               }]
           }],
     rightdata:{
         title:'',
         name:''
     },
     centerdata:{

     }
    };
  },
  watch:{
    activeIndex:{
      handler(val) {
      //  this.setactiveStore(val)
      }
    }
  },
  components: {
      'left-edit':leftedit,
      'right-edit':rightedit,
      'center-edit':centeredit
  },
   computed: {
    ...mapState({
      getlistarr: (state) => {
        return state.formdiy.getlist;
      },
       getformtext: (state) => {
        return state.formdiy.getformtext;
      }
    }),
  },
  methods: {
    ...mapMutations(['setactiveStore','createlistStroe','settingForm']),
      leftClick(e,i){
          this.rightdata=e
          this.activeIndex=i.length-1
          console.log(e,i.length,this.activeIndex)
           
      },
    rightclcik(e,i){
      console.log('rightclcik-----',e)
    },
    saveclick(e) {
    console.log(this.getformtext,this.getlistarr)
      let that = this;
      const platform_id = sessionGetKey("platformId");
      
    if(e){
      this.$request({
        url: this.$api.formApi.editform + `?platform_id=${platform_id}`,
        method: "post",
        data: {
          id:this.getformtext.id,
          name:this.getformtext.name,
          text:JSON.stringify(this.getlistarr)
        }
      })
        .then((res) => {
          if (res.code == 0) {
            that.settingForm({text:JSON.stringify(this.getlistarr)})
            // settingForm
            that.$message.success('保存成功')
          } else {
            that.$message.error("保存失败");
          }
          console.log(res);
        })
        .catch((err) => {
          that.$message.error("保存失败");
          console.log(err);
        });
      console.log(e)
    }
    },
    left(item,i,val){
      if(val=='del'){
 this.activeIndex= i-1
      }
      console.log(item,i,val)
    },
     right(item,i,val){
       let that=this
         if(val=='down'&&this.getlistarr.length>this.activeIndex+1){
          this.activeIndex=this.activeIndex+1
         }else if(val=='up'&&this.activeIndex){
           this.activeIndex=this.activeIndex-1
         }
        let s={
           name:this.getlistarr[this.activeIndex].name,
           title:''
         }
          this.leftdata[0].data.forEach(value=>{
          if(value.name==s.name){
           s.title=value.title
          }
        })
        this.rightdata=s
       console.log(item,i,val,this.getlistarr,s)
     },
      select(item,i,val){
        console.log(i, this.activeIndex)
          this.activeIndex=i
        this.leftdata[0].data.forEach(value=>{
          if(value.name==item.name){
            this.rightdata=value
          }
        })
        console.log(item,i,val)
      }
  },
};
</script>

<style lang="scss" scoped>
.formment-box {
  // height: 100%;
 padding: 66px;
  //   min-height: 600px;
 
.center-box-big{
  max-height: 600px;
  height:600px;
  overflow: hidden;
  overflow-y: auto;
  min-width: 500px;
  max-width:500px;
  scrollbar-width:none;
  -ms-overflow-style:none;
  &::-webkit-scrollbar{
display: none;
}
}

  .setbox {
    margin-top: 20px;
    color: #333333;

    .title {
      background: #edefff;
      height: 35px;
      line-height: 35px;
      padding-left: 15px;
    }
   
    .inputbox {
      margin-left: 15%;

      margin-top: 24px;
    }
  }

  .box {
     border-radius: 16px;
    overflow: hidden;
    // position: relative;
    width: 100%;
    height: 100%;
    background: #fff;
    padding: 24px;
    .top-nav {
      display: flex;
      justify-content: space-between;
      align-items: center;
      /deep/.el-breadcrumb__item {
        padding-bottom: 10px;

        font-size: 20px !important;
        //
      }
      /deep/.active {
        color: #4458fe !important;
        border-bottom: #4458fe 2px solid;
        .el-breadcrumb__inner {
          color: #4458fe !important;
        }
      }
    }
    .btn-box{
     display: flex;
     justify-content: flex-end;
     .el-button{
       background: #4458FE;
       color: #fff;
       width: 100px;
     }

    }
  }
}
</style>