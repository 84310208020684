<template>
    <div v-if="show=='Uploadpictures'">
           <el-form ref="form" :model="form" label-width="90px">
  <el-form-item label="字段属性">
    <el-radio-group v-model="form.data.is_required">
      <el-radio :label="1">必填</el-radio>
      <el-radio :label="0">选填</el-radio>
    </el-radio-group>
  </el-form-item>
  <el-form-item label="内容标题">
    <!-- <el-switch v-model="form.data"></el-switch> -->
     <el-input v-model="form.data.title" placeholder="请输入内容标题"></el-input>
  </el-form-item>
   <el-form-item label="提示文字">
    <el-input v-model="form.data.placeholder" placeholder="请输入提示文字"></el-input>
  </el-form-item>
  <el-form-item label="最大上传数">
    <el-input type="number" v-model="form.data.maxNum" placeholder="最大上传数"></el-input>
  </el-form-item>
        </el-form>
    </div>
</template>
<script>
import {mapState,mapMutations,mapGetters,mapActions} from 'vuex'
export default {
    data() {
        return {
              form:{
                 id: "uploadImg",
      
        name:'Uploadpictures',
        data: {
          is_required: 1,
          placeholder: "",
          title: "",
          maxNum:5
        }
              }
        }
    },
     props:{
        show:{
            type:[String,Number,Boolean,Object]
        }
    },
     watch:{
        active:{
      handler(val){
        if(this.show==this.form.name){
          this.addlistStoredata(this.form)
//  console.log(this.form)
        }
      }
    },
    form:{
      handler(val){
        this.$emit('diyclcik',val)
        console.log('diy---',val)
      },
      // immediate:true,
      deep:true
    }
  },
   computed: {
    ...mapState({
      active: (state) => {
        return state.formdiy.active;
      },
    }),
  },
  methods:{
      ...mapMutations(['addlistStoredata']),
  }
}
</script>
<style lang="scss" scoped>

</style>