<template>
  <!--  复选框1-->
  <div v-if="show == 'checkbox'">
    <el-form ref="form" :model="form" label-width="80px">
      <el-form-item label="字段属性">
        <el-radio-group v-model="form.data.is_required">
          <el-radio :label="1">必填</el-radio>
          <el-radio :label="0">选填</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="内容标题">
        <el-switch v-model="form.data.type"></el-switch>
        <el-input
          v-model="form.data.title"
          placeholder="请输入内容标题"
        ></el-input>
      </el-form-item>
      <el-form-item label="添加选项">
        <el-button
          v-for="(item, index) in form.data.list"
          :key="index"
          class="add-btn"
          style="background: #fff; color: #606266; border-color: #dcdfe6"
        >
          <i class="el-icon-error" @click="del(item, index)"></i>
          <span class="fonthidden">{{ item.label }}</span>
        </el-button>
        <el-button plain @click="addclcik" class="add-btn">添加</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import {mapState,mapMutations,mapGetters,mapActions} from 'vuex'
export default {
  data() {
    return {
      form: {
        id: "table",
        name: "checkbox",
        data: {
          is_required: 1,
          placeholder: "",
          title: "",
          list: [
            //  {"label":"白露一闪，黄河入海流，一穷前六亩","unit":"个"}
          ],
        },
      },
    };
  },
  props: {
    show: {
      type: [String, Number, Boolean, Object],
    },
  },
  watch: {
      active:{
      handler(val){
        if(this.show==this.form.name){
          this.addlistStoredata(this.form)
//  console.log(this.form)
        }
      }
    },
    form: {
      handler(val) {
        this.$emit("diyclcik", val);
        console.log("diy---", val);
      },
      // immediate:true,
      deep: true,
    },
  },
   computed: {
    ...mapState({
      active: (state) => {
        return state.formdiy.active;
      },
    }),
  },
  methods: {
      ...mapMutations(['addlistStoredata']),
    addclcik() {
      let that = this;

      let len = this.form.data.list.length + 1;
      this.$prompt("请输入合作商", "", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputErrorMessage: "",
      })
        .then(({ value }) => {
          if (value.length > 15) {
            this.$message({
              message: "不可超过15个字",
            });
            return;
          }
          that.form.data.list.push({
            label: value,
            unit: "个",
          });
        })
        .catch(() => {});
    },
    del(item, i) {
      this.form.data.list.splice(i, 1);
    },
  },
};
</script>
<style lang="scss" scoped>
.add-btn {
  width: 100px;
  margin: 0 10px 10px 0px;

  position: relative;
  .fonthidden {
    display: block;
    // width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .el-icon-error {
    position: absolute;
    right: -10px;
    top: -10px;
    font-size: 22px;
    color: #999999;
  }
}
</style>