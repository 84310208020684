<template>
<!-- 单行1 -->
  <div v-if="show == 'Singlelinetext'">
    <el-form ref="form" :model="form" label-width="80px">
      <el-form-item label="字段属性">
        <el-radio-group v-model="form.data.is_required">
          <el-radio :label="1">必填</el-radio>
          <el-radio :label="0">选填</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="内容标题">
        <el-switch v-model="form.data.type"></el-switch>
        <el-input v-model="form.data.title" placeholder="名称"></el-input>
      </el-form-item>
      <el-form-item label="提示文字">
        <el-input v-model="form.data.placeholder" placeholder="请输入名称"></el-input>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import {mapState,mapMutations,mapGetters,mapActions} from 'vuex'
export default {
  data() {
    return {
      form: {
        id: "input",
       
        name:'Singlelinetext',
        data: {
          is_required: 1,
          placeholder: "",
          title: "",
        }
      },
    };
  },
  props: {
    show: {
      type: [String, Number, Boolean, Object],
    },
  },
   computed: {
    ...mapState({
      active: (state) => {
        return state.formdiy.active;
      },
    }),
  },
  watch:{
     active:{
      handler(val){
        if(this.show==this.form.name){
          this.addlistStoredata(this.form)
        }
      }
    },
    form:{
      handler(val){
        this.$emit('diyclcik',val)
      },
      // immediate:true,
      deep:true
    }
  },
  methods:{
      ...mapMutations(['addlistStoredata']),
  }
};
</script>
<style lang="scss" scoped>
</style>