<template>
  <div v-if="show == 'Multilinetext'">
    <el-form ref="form" :model="form" label-width="80px">
      <el-form-item label="字段属性">
        <el-radio-group v-model="form.data.is_required">
          <el-radio :label="1">必填</el-radio>
          <el-radio :label="0">选填</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="内容标题">
        <el-switch v-model="form.data.type"></el-switch>
      </el-form-item>
      <el-form-item label="提示文字">
        <el-input
          v-model="form.data.placeholder"
          placeholder="请输入详细地址"
        ></el-input>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import { mapState, mapMutations, mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {
      form: {
        id: "textarea",
        name: "Multilinetext",
        data: {
          is_required: 1,
          placeholder: "",
          title: "",
        },
      },
    };
  },
  props: {
    show: {
      type: [String, Number, Boolean, Object],
    },
  },
   computed: {
    ...mapState({
      active: (state) => {
        return state.formdiy.active;
      },
    }),
  },
  watch: {
      active:{
      handler(val){
        if(this.show==this.form.name){
          this.addlistStoredata(this.form)
//  console.log(this.form)
        }
      }
    },
    form: {
      handler(val) {
        this.$emit("diyclcik", val);
        console.log("diy---", val);
      },
      // immediate:true,
      deep: true,
    },
  },
  methods: {
    ...mapMutations(["addlistStoredata"]),
  },
};
</script>
<style lang="scss" scoped>
</style>